<template>
  <v-container fill-height class="fill-height wena" fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
        xl="4"
      >
        <v-system-bar
          color="primary2"
          dark
          class="pb-7"
        >
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <v-form
          ref="form"
          @submit.prevent="submit"
        >
          <v-card class="pb-10">
            <v-row justify="center">
              <v-col
                cols="4"
                md="5"
              >
                 <v-responsive class="pt-3 px-12 mx-2 pb-3">
            <v-img
              width="80px"
              src="/login.svg"
            ></v-img>
          </v-responsive>
              </v-col>
            </v-row>
            <v-card-title class="subtitle-1 text-center justify-center pb-10">{{texts.title}}</v-card-title>
            <v-card-text v-if="showEmail" class="px-10">
              <!-- <div v-show="showError" class="error--text text-center">{{ error }}</div> -->
              <v-alert
                v-show="showError"
                dense
                outlined
                type="error"
                class="text-center"
              >
                {{ error }}
              </v-alert>
              <v-text-field
                v-model="user.email"
                label="E-mail"
                outlined
                name="email"
                prepend-inner-icon="email"
                type="text"
                dense
                required
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                :label="$t('password')"
                outlined
                name="password"
                prepend-inner-icon="lock"
                type="password"
                dense
                :rules="requiredRules"
              ></v-text-field>
              <v-text-field
                v-model="repeatPassword"
                :label="$t('confirm_password')"
                outlined
                prepend-inner-icon="lock"
                type="password"
                dense
                required
                hide-details
                :rules="repeatPasswordRules"
              ></v-text-field>
            </v-card-text>
            <v-card-actions 
              v-if="showEmail"
              class="px-10"
            >
              <v-btn
                :loading="isLoading"
                :disabled="$v.$invalid || isLoading"
                type="submit"
                block
                color="primary"
                large
              >{{texts.button}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { LOGIN_CANDIDATE_IMPORTED_MUTATION } from './../graphql/Mutation'
import { formatError } from '@/utils'
import { GC_AUTH_TOKEN, GC_USER_ID } from '@/constants/settings'
import { mapActions } from 'vuex'
export default {
  name: 'Login',
  data: () => ({
    currentFlag: "",
    error: undefined,
    noInternet: false,
    isLogin: true,
    isLoading: false,
    repeatPassword: '',
    showEmail: true,
    showError: false,
    user: {
      name: '',
      email: '',
      password: ''
    }
  }),
  validations () {
    const validations = {
      user: {
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(6)
        }
      }
    }

    if (this.isLogin) { return validations }

    return {
      user: {
        email: validations.user.email
      }
    }
  },
  created () {
    this.currentFlag = localStorage.getItem("flag") ? localStorage.getItem("flag") : 'pt';
    this.noInternet = window.navigator.onLine ? false : true
    if (this.noInternet) {
      this.error = 'No Internet Connection'
      this.showError = true
    }
  },
  computed: {
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    texts () {
      return this.isLogin
        ? { title: 'Login', button: this.$t('btn_login') }
        : this.showEmail
          ? { title: 'Insira o email utilizado na criação da conta', button: 'Submeter' }
          : { title: 'Enviamos um email, com o link que deverá aceder para alterar a senha.', button: 'Submeter' }
    },
    emailRules (){
      return [
        v => !!v || `${this.$t('required_field')}`,
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    },
    repeatPasswordRules () {
      return [v => v === this.user.password || `${this.$t('diff_password')}`]
    },
    requiredRules () {
      return [v => !!v || `${this.$t('required_field')}`]
    },
    emailErrors () {
      const errors = []
      const email = this.$v.user.email
      if (!email.$dirty) {
        return errors
      }
      !email.required && errors.push(`${this.$t('email_required')}`)
      !email.email && errors.push(`${this.$t('enter_valid_email')}`)

      return errors
    },
    passwordErrors () {
      const errors = []
      const password = this.$v.user.password
      if (!password.$dirty) {
        return errors
      }
      !password.required && errors.push(`${this.$t('password_required')}`)
      !password.minLength && errors.push(`Insira pelo menos ${password.$params.minLength.min} caracteres!`)

      return errors
    }
  },
  methods: {
    ...mapActions({
      setUserState: 'auth/setUser'
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
    saveUserData (id, token) {
      localStorage.setItem(GC_USER_ID, id)
      localStorage.setItem(GC_AUTH_TOKEN, token)
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID)
    },
    async submit () {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: LOGIN_CANDIDATE_IMPORTED_MUTATION,
          variables: {
            ...this.user,
            candidateId: this.$route.query.candidateId
          }
        })
        .then(({ data }) => {
          const id = data.loginForCandidateInmported.user.id
          const token = data.loginForCandidateInmported.token
          this.saveUserData(id, token)
          this.setUserState(data.loginForCandidateInmported.user)
          this.$router.push(this.$route.query.redirect || '/candidate')
        });
      } catch (error) {
        this.error = formatError(error.message)
        this.noInternet = window.navigator.onLine ? false : true
        if (this.noInternet) {
          this.error = 'No Internet Connection'
        }
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.recover-login {
  cursor: pointer;
}
.wena {
    background: #F5FAFF;
}
</style>